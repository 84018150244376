import React, { useState } from 'react';
import brush2 from '../images/brush2.png';
import image from '../images/image.png';
import image0 from '../images/image0.png';
import image2 from '../images/image2.png';
import Image3 from '../images/Image3.jpeg';
import image4 from '../images/image4.png';
import video from '../images/video.mp4';
import Navbar from './Navbar';
import Footer from './Footer';

const articles = [
  {
    img: image,
    title: 'A Canvas of Endless Possibilities',
    description: 'Explore the inspirations, techniques, and perspectives on the role of art in our lives.',
    content: 'Detailed content about the canvas of endless possibilities...',
  },
  {
    img: image2,
    title: 'The Evolution of Modern Art',
    description: 'Discover the journey and milestones of modern art through the years.',
    content: 'Detailed content about the evolution of modern art...',
  },
  {
    img: Image3,
    title: 'Art in the Digital Age',
    description: 'Explore how technology is transforming the world of art and creativity.',
    content: 'Detailed content about art in the digital age...',
  },
  {
    img: image4,
    title: 'Beyond the Canvas: New Art Forms',
    description: 'Uncover the innovative art forms that are redefining artistic expression.',
    content: 'Detailed content about new art forms...',
  }
];

const BlogSection = () => {
  const [isHeroModalOpen, setIsHeroModalOpen] = useState(false);

  const openHeroModal = () => {
    setIsHeroModalOpen(true);
  };

  const closeHeroModal = () => {
    setIsHeroModalOpen(false);
  };

  return (
    <div className="blog-section">
      <Navbar />
      {/* Hero Section */}
      <div className="hero-section flex items-center justify-center h-96" style={{ background: `url(${brush2}) left center / contain no-repeat` }}>
        <div className="hero-overlay bg-black bg-opacity-50 p-8 text-center text-white">
          <h1 className="text-4xl font-bold mb-4">Explore Our Blog</h1>
          <p className="mb-4">Discover the latest articles and interviews with artists, exploring their inspirations, techniques, and perspectives.</p>
          <button
            className="cta-button bg-orange-500 hover:bg-orange-600 text-white py-2 px-4 rounded"
            onClick={openHeroModal}
          >
            Read More
          </button>
        </div>
      </div>
      {/* Hero Modal */}
      {isHeroModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-50 overflow-auto">
          <div className="bg-white p-6 rounded-lg max-w-4xl w-full md:w-3/4 lg:w-1/2 relative">
            <button
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
              onClick={closeHeroModal}
            >
              ✖
            </button>
            <div className="text-center">
              <h2 className="text-3xl font-bold mb-4">Explore Our Blog</h2>
              <p className="mb-4">Discover the latest articles and interviews with artists, exploring their inspirations, techniques, and perspectives. Our blog provides insights into the creative world and showcases the diverse voices in art. Dive deeper into our featured content and join the conversation about art and creativity.</p>
              <button
                className="cta-button bg-orange-500 hover:bg-orange-600 text-white py-2 px-4 rounded"
                onClick={closeHeroModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Categories Section */}
      <div className="categories-section py-4 text-center ">
        <h2 className="text-3xl font-bold mb-8">Categories</h2>
        <div className="categories-grid justify-center grid grid-cols-1 sm:grid-cols-3 gap-4">
          {['Art', 'Galleries/Museums', 'Collections'].map(category => (
            <div key={category} className="category-card bg-orange-600 border border-gray-200 p-6">
              {category}
            </div>
          ))}
        </div>
      </div>
      {/* Featured Articles Section */}
      <div className="featured-articles-section py-4 text-center">
        <h2 className="text-3xl font-bold mb-8">Check out what's new?</h2>
        <div className="articles-carousel flex justify-center flex-wrap gap-2">
          {articles.map((article, index) => (
            <div key={index} className="article-card bg-white border border-gray-200 p-6 max-w-xs">
              <img src={article.img} alt={`Article ${index + 1}`} className="w-full h-96 object-cover mb-4" />
              <h3 className="text-xl font-bold mb-2">{article.title}</h3>
              <p className="mb-4">{article.description}</p>
            </div>
          ))}
        </div>
      </div>
      {/* Trending Section */}
      <div className="trending-section py-4">
        <h2 className="text-3xl font-bold mb-8">Trending</h2>
        <div className="flex justify-center items-center">
          <div className="max-w-4xl">
            <div className="text-left">
              <h3 className="text-2xl font-bold mb-2">Explore a world beyond your wildest dreams</h3>
              <p className="mb-4">If you're looking for a way to connect with other art lovers, our community section provides a place for you to share your thoughts, engage in lively discussions, and join our growing network of art enthusiasts.</p>
              <p>From emerging artists to established masters, we showcase the best of contemporary art and provide a window into the creative process behind it.</p>
            </div>
            <div className="mt-8">
              <img src={image0} alt="Trending Article" className="w-full h-80 object-cover mb-4" />
            </div>
          </div>
        </div>
      </div>
      {/* Video Section */}
      <div className="video-section py-2 text-center">
        <h2 className="text-3xl font-bold mb-8">Discover Art Through Our Lens</h2>
        <div className="flex justify-center">
          <video controls className="w-full max-w-4xl">
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogSection;
