import { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const API_BASE_URL = 'https://artvista-zozv.onrender.com';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState(localStorage.getItem('authToken') || '');
    const [isAuthenticated, setIsAuthenticated] = useState(!!authToken);
    const [user, setUser] = useState(null);
    const [authError, setAuthError] = useState(null);

    useEffect(() => {
        if (authToken) {
            localStorage.setItem('authToken', authToken);
        } else {
            localStorage.removeItem('authToken');
        }
    }, [authToken]);

    const login = async (email, password) => {
        try {
            const response = await axios.post(`${API_BASE_URL}/api/token/`, { email, password });
            setAuthToken(response.data.access);
            setIsAuthenticated(true);
            setAuthError(null);
            await fetchUser(response.data.access);
            return true;
        } catch (error) {
            setAuthError(error.response?.data?.detail || 'An error occurred. Please try again.');
            return false;
        }
    };

    const logout = async () => {
        try {
            // For JWT, you typically clear the token on the client side
            setAuthToken('');
            setIsAuthenticated(false);
            setUser(null);
        } catch (error) {
            console.error('Logout error:', error.response?.data?.detail || 'An error occurred.');
        }
    };

    const fetchUser = async (token) => {
        try {
            const userResponse = await axios.get(`${API_BASE_URL}/api/auth/users/me/`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setUser(userResponse.data);
        } catch (error) {
            console.error('Fetch user error:', error.response?.data?.detail || 'An error occurred.');
        }
    };

    useEffect(() => {
        if (isAuthenticated && authToken) {
            fetchUser(authToken);
        }
    }, [isAuthenticated, authToken]);
    return (
        <AuthContext.Provider value={{ authToken, isAuthenticated, login, logout, authError, user }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
