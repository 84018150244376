import 'react-responsive-carousel/lib/styles/carousel.min.css';
import co1 from "../images/co1.jpeg";
import co2 from "../images/co2.jpeg";
import co3 from "../images/co3.jpeg";
import co6 from "../images/co6.JPG";
import { Carousel } from 'react-responsive-carousel';

export default function CarouselTransition({ toggleJoinForm }) {
  return (
    <div className="carousel-section relative py-0 px-4 sm:px-8 lg:px-16 bg-gray-200">
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold text-gray-900 mb-2">Featured Artwork</h2>
        <p className="text-lg text-gray-600">Explore some of the finest pieces from our collection</p>
      </div>
      <div className="relative">
        <Carousel
          showThumbs={false}
          autoPlay
          interval={7000}
          infiniteLoop
          dynamicHeight={false}
          showStatus={false}    
          className="rounded-xl shadow-lg overflow-hidden"
        >
          <div className="carousel-image-container bg-gray-100">
            <img src={co1} alt="Artwork 1" className="carousel-image object-cover w-full h-96" />
          </div>
          <div className="carousel-image-container bg-gray-100">
            <img src={co2} alt="Artwork 2" className="carousel-image object-cover w-full h-96" />
          </div>
          <div className="carousel-image-container bg-gray-100">
            <img src={co3} alt="Artwork 3" className="carousel-image object-cover w-full h-96" />
          </div>
          <div className="carousel-image-container bg-gray-100">
            <img src={co6} alt="Artwork 3" className="carousel-image object-cover w-full h-96" />
          </div>
        </Carousel>
        <div className="absolute inset-0 flex items-center justify-between px-4 lg:px-16">
          <div className="hidden lg:block w-1/3 text-left">
            <h3 className="text-2xl font-bold text-gray-900 mb-2">Discover More</h3>
            <p className="text-lg text-gray-600 mb-4">Dive deeper into our gallery and find inspiration.</p>
            <a href='/Gallery' >
            <button className="bg-blue-800 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              Learn More
            </button></a>
          </div>
          <div className="hidden lg:block w-1/3 text-left">
            <h3 className="text-2xl font-bold text-gray-900 mb-2">Join Us</h3>
            <p className="text-lg text-gray-600 mb-4">Become a part of our vibrant art community.</p>
            <button
              className="bg-blue-800 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              onClick={toggleJoinForm}
            >
              Sign Up
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
