import { useState } from 'react';
import axios from 'axios';

const RequestActivation = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleResend = async () => {
    try {
      await axios.post(`https://artvista-zozv.onrender.com/api/auth/users/resend_activation/`, {
        email,
      });
      setMessage('Activation email resent successfully. Please check your inbox.');
    } catch (error) {
      setMessage('Failed to resend activation email. Please try again.');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
      <h1 className="text-4xl font-bold text-gray-800 mb-4">Activate Your Account</h1>
      <p className="text-gray-600 mb-6 text-center max-w-lg">
        Thank you for registering! We've sent an activation link to your email. Please check your inbox and follow the instructions to activate your account.
      </p>
      <p className="text-gray-600 mb-6 text-center max-w-lg">
        If you didn't receive the email, please check your spam folder or enter your email address below to resend the activation link.
      </p>
      <input
        type="email"
        placeholder="Enter your email address"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="w-full max-w-md p-2 mb-4 border border-gray-300 rounded"
      />
      <button
        onClick={handleResend}
        className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline"
      >
        Resend Activation
      </button>
      {message && <p className="mt-4 text-center text-blue-900 text-lg">{message}</p>}
    </div>
  );
};

export default RequestActivation;
