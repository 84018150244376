import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import Footer from './Footer';

const Events = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get('https://artvista-zozv.onrender.com/api/events/');
        setEvents(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const openModal = (event) => {
    setSelectedEvent(event);
  };

  const closeModal = () => {
    setSelectedEvent(null);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="flex items-center space-x-2">
          <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent border-solid rounded-full animate-spin"></div>
          <div className="text-xl text-blue-500">Loading...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <p className="text-center mt-10 text-red-500">
        Error fetching events: {error.message}
        <br />
        Please reload this page, if the issue persists please contact support.
      </p>
    );
  }

  return (
    <div className="relative">
      <Navbar />
      <div className="bg-gray-100 py-10 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-3xl font-bold text-center mb-6 text-gray-900">Art Events Calendar</h1>
          <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {events.map((event) => (
              <div
                key={event.id}
                className="bg-white rounded-lg shadow-lg overflow-hidden cursor-pointer"
                onClick={() => openModal(event)}
              >
                <div className="h-48">
                  <img src={event.image} alt={event.title} className="w-full h-full object-cover" />
                </div>
                <div className="p-4">
                  <h2 className="text-xl font-bold mb-2 text-gray-900">{event.title}</h2>
                  <p className="text-gray-700">{event.description.slice(0, 100)}...</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {selectedEvent && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-white overflow-auto p-4">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-3xl w-full relative">
            <button className="absolute top-1 right-4 text-gray-500 text-5xl" onClick={closeModal}>
              &times;
            </button>
            <div className="flex flex-col md:flex-row">
              <div className="md:w-1/2">
                <img src={selectedEvent.image} alt={selectedEvent.title} className="w-full h-84 object-cover rounded-md" />
              </div>
              <div className="md:w-1/2 md:pl-6 mt-4 md:mt-0">
                <h2 className="text-3xl font-bold mb-4 text-gray-900">{selectedEvent.title}</h2>
                <p className="text-gray-700 mb-4">{selectedEvent.description}</p>
                <p className="text-gray-700 mb-2"><strong>Entry Fee:</strong> {selectedEvent.entry_fee}</p>
                <p className="text-gray-700 mb-4"><strong>Location:</strong> {selectedEvent.location}</p>
                <p className="text-gray-700 mb-4"><strong>Opening Hours:</strong> {selectedEvent.gallery_opening_hours}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Events;
