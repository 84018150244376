import React from 'react';
import TestimonyCard from './TestimonyCard ';
function TestimoniesSection() {
  const testimonies = [
    {
      id: 1,
      quote: "ArtVista has been instrumental in helping me discover new artists and expand my collection.",
      author: "Michael Owen",
      role: "Art Enthusiast",
    },
    {
      id: 2,
      quote: "I love how ArtVista brings together artists and art lovers. It's a great platform for creativity.",
      author: "Sarah Gill",
      role: "Artist",
    },
    {
      id: 3,
      quote: "ArtVista's community has been incredibly supportive of my work. I couldn't have asked for a better platform.",
      author: "Kelvin Mannaseh",
      role: "Painter",
    },
  ];

  return (
    <section className="testimonies-section bg-gray-200 py-12 px-4 sm:px-8 lg:px-16">
      <div className="max-w-5xl mx-auto">
        <h2 className="text-3xl font-bold text-center text-gray-900 mb-8">What Our Users Say</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {testimonies.map((testimony) => (
            <TestimonyCard key={testimony.id} testimony={testimony} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default TestimoniesSection;
