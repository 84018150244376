import React from 'react';
import Slider from 'react-slick';
import Artist1 from '../images/Artist1.jpg';
import Artist2 from '../images/Artist2.png';
import Artist3 from '../images/Artist3.jpeg';
import Artist4 from '../images/Artist4.jpg';

// Artist data
const artists = [
  {
    name: 'Joart Kabeya',
    description: 'Kabeya addresses themes of cultural, economic, political, and social resonance, echoing the experiences of the voiceless and marginalized in modern interconnected African society.',
    image: Artist1,
  },
  {
    name: 'Avni Shah',
    description: 'Through explorations in contemporary art, the artist has found a powerful medium for new perception, expression, and an awakening of new consciousness.',
    image: Artist2,
  },
  {
    name: 'Petrix Peter',
    description: 'His works focus on women, men, and kids from Africa and the African diaspora, often from his community of Kibera. He includes real people he knows in his works whom he hears, sees, and even feels their struggles.',
    image: Artist3,
  },
  {
    name: 'Victoria Erioluwa',
    description: 'Drawing elements from both historical and contemporary painting techniques, she puts together a composition of work to tell a story about her recurring experiences and observation of general lifestyles.',
    image: Artist4,
  },
];

// Carousel settings
const carouselSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true, // Enable auto-slide
  autoplaySpeed: 7000, // Adjust slide duration (in milliseconds)
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true, // Show navigation arrows
};

const FeaturedArtists = () => {
  return (
    <div className="featured-artists-section py-9 px-4 sm:px-8 lg:px-16 bg-gray-100">
      <h2 className="text-3xl font-bold text-center mb-8">Some of Our Featured Artists</h2>

      {/* Carousel for small screens */}
      <div className="block md:hidden">
        <Slider {...carouselSettings}>
          {artists.map((artist, index) => (
            <div key={index} className="artist-card bg-white shadow-lg rounded-lg p-6 flex flex-col items-center">
              <img
                src={artist.image}
                alt={artist.name}
                className="w-full h-64 object-cover rounded-t-lg" // Increased height for larger images
              />
              <h3 className="text-xl text-center font-semibold mt-4">{artist.name}</h3>
              <p className="text-gray-700 mt-2 text-center">{artist.description}</p>
            </div>
          ))}
        </Slider>
      </div>

      {/* Grid layout for larger screens */}
      <div className="hidden md:grid md:grid-cols-2 lg:grid-cols-4 gap-8">
        {artists.map((artist, index) => (
          <div key={index} className="artist-card bg-white shadow-lg rounded-lg p-6 flex flex-col items-center">
            <img
              src={artist.image}
              alt={artist.name}
              className="w-full h-64 object-cover rounded-t-lg"
            />
            <h3 className="text-xl font-semibold mt-4">{artist.name}</h3>
            <p className="text-gray-700 mt-2 text-center">{artist.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturedArtists;
