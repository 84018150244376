import { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import Navbar from "./Navbar";
import JoinForm from "./Form";
import LoginForm from "./Login";
import Footer from "./Footer";
import "animate.css";
import "../App.css";
import FeaturedArtists from "./FeaturedArtists";
import NotificationPopup from "./NotificationPopup";
import section from "../images/section.png";
import AuthContext from "./AuthContext";
import CarouselTransition from "./CarouselTransition";
import ReadCorner from "./ReadCorner";
import TestimoniesSection from "./TestimoniesSection";

function Home() {
  const { isAuthenticated } = useContext(AuthContext);
  const [isJoinFormOpen, setIsJoinFormOpen] = useState(false);
  const [isLoginFormOpen, setIsLoginFormOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(true);

  const toggleJoinForm = () => {
    setIsJoinFormOpen(!isJoinFormOpen);
  };

  const toggleLoginForm = () => {
    setIsLoginFormOpen(!isLoginFormOpen);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <Helmet>
        <title>ArtVista - Connect with Fellow Artists</title>
        <meta name="description" content="Join ArtVista, a vibrant community where artists can showcase their work and connect with fellow creatives." />
        <meta name="keywords" content="art, artists, gallery, art community, artistic work, meet artists" />
        <meta property="og:title" content="ArtVista - Connect with Fellow Artists" />
        <meta property="og:description" content="Join ArtVista, a vibrant community where artists can showcase their work and connect with fellow creatives." />
        <meta property="og:image" content={`${process.env.PUBLIC_URL}/logo3.jpeg`} />
        <meta property="og:url" content="http://theartvista.com/" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="ArtVista - Connect with Fellow Artists" />
        <meta name="twitter:description" content="Join ArtVista, a vibrant community where artists can showcase their work and connect with fellow creatives." />
        <meta name="twitter:image" content={`${process.env.PUBLIC_URL}/logo3.jpeg`} />
        <link rel="icon" href={`${process.env.PUBLIC_URL}/logo3.jpeg`} />
        <link rel="apple-touch-icon" href={`${process.env.PUBLIC_URL}/logo3.jpeg`} />
      </Helmet>
      <Navbar onJoinClick={toggleJoinForm} onLoginClick={toggleLoginForm} />
      <div className={isJoinFormOpen || isLoginFormOpen ? "blur" : ""}>
        <div className="mx-auto">
          <div className="wrapper-inner">
            {showPopup && <NotificationPopup onClose={handleClosePopup} />}
            <section className="hero">
              <img src={section} alt="section" style={{ width: "100%" }} />
            </section>
            <div className="text-center lg:text-left mx-auto py-1 px-3 sm:px-10 lg:px-10 max-w-5xl">
              <h2 className="font-bold text-3xl sm:text-4xl lg:text-5xl mb-6 text-gray-900">
                Do you Love Art?
              </h2>
              <p className="text-lg sm:text-xl lg:text-2xl text-gray-700 mb-8">
                Whether you are an artist looking to showcase your work, or simply
                someone who loves to admire and learn about art, our website is
                the perfect place to start.
              </p>
              {!isAuthenticated && (
                <div className="flex justify-center lg:justify-start">
                  <button
                    className="bg-gradient-to-r from-blue-500 to-purple-600 text-white font-semibold py-3 px-6 rounded-full shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl hover:from-blue-600 hover:to-purple-700"
                    onClick={toggleJoinForm}
                  >
                    Join ArtVista
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="carousel-section py-4 px-4 sm:px-8 lg:px-16 bg-gray-100">
            <CarouselTransition toggleJoinForm={toggleJoinForm} />
          </div>
          <div className="featured-artists-section py-1 px-4 sm:px-8 lg:px-16 bg-gray-100"> {/* Add the FeaturedArtists component */}
            <FeaturedArtists />
          </div>
          <div className="carousel-section py-1 px-4 sm:px-8 lg:px-16 bg-gray-100">
            <ReadCorner />
          </div>
          <div className="about-us-section py-8 px-4 sm:px-8 lg:px-16 bg-gray-100">
            <h2 className="text-3xl font-bold text-center mb-8">About Us</h2>
            <div className="flex flex-col md:flex-row justify-center items-start gap-4">
              <div className="about-card bg-blue-400 shadow-lg rounded-lg p-6 md:w-1/3">
                <h3 className="text-xl font-semibold mb-4">Our Story</h3>
                <p>
                  Born from a passion for the arts, we bridge the gap between talented artists and enthusiastic collectors. We're here to transform the art world, one masterpiece at a time.
                </p>
              </div>
              <div className="about-card bg-blue-400 shadow-lg rounded-lg p-6 md:w-1/3">
                <h3 className="text-xl font-semibold mb-4">Our Vision</h3>
                <p>
                  To become the go-to hub for art enthusiasts and collectors, fostering a thriving community that celebrates and supports artistic innovation.
                </p>
              </div>
              <div className="about-card bg-blue-400 shadow-lg rounded-lg p-6 md:w-1/3">
                <h3 className="text-xl font-semibold mb-4">Our Mission</h3>
                <p>
                  Our mission is to bring people together through a shared appreciation of creativity and imagination, and to provide a platform for artists to showcase their work and connect with their audience.
                </p>
              </div>
            </div>
          </div>
          <div>
            <TestimoniesSection />
          </div>
          <div className="cta-section bg-black py-12 px-4 sm:px-8 lg:px-16 text-center text-white">
            <h2 className="text-3xl font-bold mb-4">Ready to Explore More?</h2>
            <p className="text-lg mb-6">Join ArtVista today and experience art like never before.</p>
            <a
              href="#"
              className="bg-white text-blue-500 hover:bg-blue-400 font-bold py-2 px-3 rounded-full inline-block transition duration-300"
              onClick={toggleJoinForm}
            >
              Sign Up Now
            </a>
          </div>
          <Footer />
        </div>
      </div>
      {isJoinFormOpen && <JoinForm onClose={toggleJoinForm} />}
      {isLoginFormOpen && <LoginForm onClose={toggleLoginForm} />}
    </div>
  );
}

export default Home;
