import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Landing';
import Gallery from './components/Gallery';
import Events from './components/Events';
import Terms from './components/Terms';
import Activate from './components/Activate';
import RequestActivation from './components/RequestActivation';
import NotFoundPage from './components/404';
import LoginPage from './components/Login';
import JoinForm from './components/Form';
import ForgotPassword from './components/ForgotPassword';
import About from './components/About';
import PasswordResetConfirm from './components/PasswordResetConfirm';
import PasswordResetSuccess from './components/PasswordResetSuccess';
import TestimoniesSection from './components/TestimoniesSection';
import TestimonyCard from './components/TestimonyCard ';
import BlogSection from './components/BlogSection';
import ContactUs from './components/ContactUs';
import FeaturedArtists from './components/FeaturedArtists';
function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/Contacts' element={<ContactUs />} />
        <Route path="/Form" element={<JoinForm />} />
        <Route path='/gallery' element={<Gallery />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/activate' element={<RequestActivation/>}/>
        <Route path='/events' element={<Events />} />
        <Route path="/activate/:uid/:token" element={<Activate />} />
        <Route path="/forgot-password" element={<ForgotPassword/>} />
        <Route path="/featuredArtists" element={<FeaturedArtists/>} />
        <Route path='/about' element={<About />} />
        <Route path='/testimoniesSection' element={<TestimoniesSection />} />
        <Route path='/testimonyCard' element={<TestimonyCard />} />
        <Route path='/blog' element={<BlogSection />} />
        <Route path='/password/reset/confirm/:uid/:token' element={<PasswordResetConfirm/>}/>
        <Route path='/password/reset/success/' element={<PasswordResetSuccess/>}/>
        <Route path='*' element={<NotFoundPage />} />

      </Routes>
    </Router>
  );
}
export default App;
