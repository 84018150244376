import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css'
import Navbar from './Navbar';
import Footer from './Footer';


const ContactUs = () => {
    const contacts = [
      { email: 'artists@theartvista.com', name: 'For artists interested in submitting their work before we lauch or to ask artist-related questions', main_name:'Artists'},
      { email: 'info@theartvista.com', name: 'For general questions and information about ArtVista', main_name:'Info' },
      { email: 'partnerships@theartvista.com', name: 'Businesses, Orgnanizations or individuals interested in partnering with ArtVista', main_name:'Partnerships' },
      { email: 'support@theartvista.com', name: 'Dedicated to addressing any issues, concerns, or support-related queries from users.', main_name:'Support' },
    ];
  
    return (
        <div><Navbar />
      <div className="contact-us-container">
        <header className="header text-center py-5">
          <h1>Contact Us</h1>
          <p>We'd love to hear from you! Please reach out with any questions or feedback.</p>
        </header>
        <section className="contact-form-section ">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="card p-4">
                  <h2 className="card-title text-center mb-4">Send Us a Message</h2>
                  <form  action="https://formspree.io/f/xzzpzqvg"
                         method="POST">
                    <div className="form-group">
                      <label htmlFor="name">Name</label>
                      <input type="text" className="form-control" id="name" name='name' placeholder="Enter your name" required />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input type="email" className="form-control" id="email" name='email' placeholder="Enter your email" required/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="message">Message</label>
                      <textarea className="form-control" id="message" rows="5" name='message' placeholder="Enter your message" required></textarea>
                    </div>
                    <button type="submit" className="btn btn-primary btn-block">Submit</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="contact-info-section py-5 bg-light">
          <div className="container">
            <div className="row">
              {contacts.map((contact, index) => (
                <div key={index} className="col-md-6 mb-4">
                 <h5 className="card-title">{contact.name}</h5>
                 <br /><br />
                  <div className="card contact-info-card">
                    <div className="card-body text-center"></div> 
                      <p className="card-text">{contact.email}</p>
                     
                      <a href={`mailto:${contact.email}`} className="btn btn-outline-primary">Email {contact.main_name}</a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <Footer/>
      </div>
      </div>
    );
  };
  
  export default ContactUs;
