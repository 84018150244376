import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import Footer from './Footer';
import JoinForm from './Form';
import LoginForm from './Login';
import AuthContext from './AuthContext';
import Navbar from './Navbar';

const GalleryPage = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isJoinFormOpen, setIsJoinFormOpen] = useState(false);
  const [isLoginFormOpen, setIsLoginFormOpen] = useState(false);
  const { isAuthenticated } = useContext(AuthContext);

  const toggleJoinForm = () => {
    setIsJoinFormOpen(!isJoinFormOpen);
  };

  useEffect(() => {
    if (!isAuthenticated) {
      setLoading(false);
      setIsLoginFormOpen(true);
    } else {
      const fetchImages = async () => {
        try {
          const response = await axios.get('https://artvista-zozv.onrender.com/api/images/');
          setImages(response.data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching images:', error);
          setLoading(false);
        }
      };
      fetchImages();
    }
  }, [isAuthenticated]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const shuffledImages = images.sort(() => Math.random() - 0.5);

  return (
    <div>
      <Navbar />
      <div className={isLoginFormOpen ? 'blur-sm' : ''}>
        <h2 className="text-3xl font-bold text-center mb-8">Discover artworks from our talented artists</h2>

        {isAuthenticated && loading ? (
          <div className="flex flex-col items-center justify-center">
            <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-blue-500"></div>
            <p className="text-blue-500 mt-4">Loading Artworks...</p>
          </div>
        ) : !isAuthenticated ? (
          <div className="flex flex-col items-center justify-center h-full">
            <p className="text-2xl text-gray-800 mb-4">Please log in to access this page.</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 p-4">
          {shuffledImages.map(image => (
            <div
              key={image.id}
              className="relative bg-gray border border-gray-200 rounded-lg shadow-lg overflow-hidden cursor-pointer flex flex-col"
              onClick={() => handleImageClick(image)}
            >
              <img
                className="w-full h-64 object-cover bg-gray"
                src={image.image_url}
                alt={image.image_title}
              />
              <div className="p-2 flex-grow">
                <h3 className="text-xl font-bold truncate">{image.image_title}</h3>
              </div>
            </div>
          ))}
        </div>
        )}
      </div>

      {isLoginFormOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75">
          <LoginForm onClose={() => setIsLoginFormOpen(false)} />
        </div>
      )}
      {isJoinFormOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75">
          <JoinForm onClose={toggleJoinForm} />
        </div>
      )}

{selectedImage && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-50 overflow-auto">
    <div className="bg-white p-4 rounded-lg max-w-4xl w-full md:w-3/4 lg:w-1/2 relative max-h-screen overflow-y-auto">
      <button
        className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
        onClick={closeModal}
      >
        ✖
      </button>
      <div className="flex flex-col items-center">
        <a
          href={selectedImage.image_url}
          target="_blank"
          rel="noopener noreferrer"
          className="flex justify-center w-full"
        >
          <img
            className="max-w-full max-h-96 object-contain"
            src={selectedImage.image_url}
            alt={selectedImage.image_title}
          />
        </a>
        <div className="mt-2">
          <h2 className="text-2xl font-bold mb-2">Title: {selectedImage.image_title}</h2>
          <p className="text-gray-700 mb-2">
            <strong>Artist:</strong> {selectedImage.artist.artist_name}
          </p>
          <p className="text-gray-700 mb-2">
            <strong>Location:</strong> {selectedImage.artist.location}
          </p>
          <p className="text-gray-700 mb-2">
            <strong>Instagram:</strong>
            {selectedImage.artist.instagram_link && (
              <a
                href={selectedImage.artist.instagram_link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-pink-500 ml-2"
              >
                <FontAwesomeIcon icon={faInstagram} className="text-xl" />
              </a>
            )}
          </p>
          <p className="text-gray-700 mb-2">
            <strong>Size:</strong> {selectedImage.size}
          </p>
          <p className="text-gray-700 mb-2">
            <strong>Medium:</strong> {selectedImage.medium}
          </p>
          <p className="text-gray-700 mb-2">
            <strong>Year:</strong> {selectedImage.year}
          </p>
          <p className="text-gray-700 mb-4">
            <strong>Description:</strong> {selectedImage.description}
          </p>

          {selectedImage.video && (
            <div className="mb-4">
              <video controls className="w-full rounded-md">
                <source src={selectedImage.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          )}

          {selectedImage.link && (
            <div className="mb-4">
              <a
                href={selectedImage.link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                Watch the creative process
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
)}

      <Footer />
    </div>
  );
};

export default GalleryPage;
