// NotificationPopup.js
import React from 'react';

const NotificationPopup = ({ onClose }) => {
  return (
    <div className="notification-popup">
      <p>We're working hard to finish the development of this site.</p>
      <p>Sign up below to receive updates and to be notified when we launch!</p>
      <button className="close-btn" onClick={onClose}>Close</button>
    </div>
  );
};

export default NotificationPopup;
