import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const Activate = () => {
  const { uid, token } = useParams();
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    document.body.classList.add("bg-gray-100", "flex", "justify-center", "items-center", "min-h-screen", "text-center");
    return () => {
      document.body.classList.remove("bg-gray-100", "flex", "justify-center", "items-center", "min-h-screen", "text-center");
    };
  }, []);

  const handleActivate = async () => {
    try {
      await axios.post(`https://artvista-zozv.onrender.com/api/auth/users/activation/`, { 
        uid,
        token,
      });
      setMessage('Activation successful! You can now log in.');
      setError(false);
      setTimeout(() => {
        navigate('/');
      }, 2000);
    } catch (error) {
      console.error('Activation error:', error.response || error);
      setMessage('Activation failed. Please check the activation link or contact support.');
      setError(true);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-center px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-4xl font-extrabold text-gray-900 mb-4">Welcome to ArtVista!</h2>
        <p className="text-lg text-gray-700 mb-6">
          Thank you for joining our community. Click the button below to activate your account.
          Once activated, you will be able to log in and start exploring our world of art.
        </p>
        {message && (
          <p className={`mb-4 ${error ? 'text-red-500' : 'text-green-500'}`}>
            {message}
          </p>
        )}
        <button
          onClick={handleActivate}
          className="w-full px-6 py-3 bg-green-500 text-white rounded-lg hover:bg-green-700 focus:outline-none focus:shadow-outline transition duration-300"
        >
          Activate
        </button>
      </div>
    </div>
  );
};

export default Activate;
