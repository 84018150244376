import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from './AuthContext';
import JoinForm from './Form';
import ForgotPassword from './ForgotPassword';
import PasswordInput from './PasswordInput';

const LoginForm = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showJoinForm, setShowJoinForm] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const { login, authToken } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const success = await login(email, password);
      setMessage(
        success
          ? 'Login successful'
          : 'Login failed: check your email and password'
      );
      if (success) {
        onClose();
      }
    } catch (error) {
      setMessage('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {showJoinForm && <div className="fixed-overlay" onClick={() => setShowJoinForm(false)} />}
      {showForgotPassword && <div className="fixed-overlay" onClick={() => setShowForgotPassword(false)} />}
      <div className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black p-6 sm:p-8 rounded-lg z-20 w-11/12 sm:max-w-md ${showJoinForm || showForgotPassword ? 'blur-background' : ''}`}>
        <h2 className="text-2xl text-white-900 font-bold mb-4">Login</h2>
        {message && (
          <p className={`mb-4 ${message.includes('successful') ? 'text-green-500' : 'text-red-500'}`}>
            {message}
          </p>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-gray-700 font-bold mb-1" htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 font-bold mb-1" htmlFor="password">Password</label>
            <PasswordInput
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={isLoading}
              placeholder="Enter password"
              errorMessage=""
            />
          </div>
          <div className="flex justify-end mt-6">
            <button
              type="button"
              className="bg-gray-400 hover:bg-red-800 hover:text-white text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
              onClick={onClose}
            >
              Close
            </button>
            <button
              type="submit"
              className="bg-blue-800 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline inline-flex items-center"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <svg aria-hidden="true" role="status" className="inline mr-3 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"></path>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"></path>
                  </svg>
                  Signing in...
                </>
              ) : (
                'Login'
              )}
            </button>
          </div>
        </form>
        <div className="mt-4 text-gray-900">
          <p>
            <Link to="#" onClick={() => setShowJoinForm(true)} className="text-blue-800 hover:text-blue-600 underline">Sign up</Link>
            <span className="mx-2">|</span>
            <Link to="#" onClick={() => setShowForgotPassword(true)} className="text-blue-800 hover:text-blue-600 underline">Forgot Password?</Link>
          </p>
        </div>
        {authToken && (
          <div className="mt-4 text-gray-900">
            <p>Your auth token:</p>
            <code className="break-words">{authToken}</code>
          </div>
        )}
      </div>
      {showJoinForm && <JoinForm onClose={() => setShowJoinForm(false)} />}
      {showForgotPassword && <ForgotPassword onClose={() => setShowForgotPassword(false)} />}
    </div>
  );
};

export default LoginForm;
