import { useState } from 'react';

const PasswordInput = ({ id, value, onChange, disabled, placeholder, errorMessage }) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(prevState => !prevState);
    };

    return (
        <div className="relative">
            <input
                type={isPasswordVisible ? 'text' : 'password'}
                id={id}
                value={value}
                onChange={onChange}
                disabled={disabled}
                placeholder={placeholder}
                className={`py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none ${errorMessage ? 'border-red-500' : ''}`}
                required
            />
            <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute top-0 end-0 p-3.5 rounded-e-md"
            >
                <svg
                    className="flex-shrink-0 size-3.5 text-gray-400"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path className={isPasswordVisible ? 'hidden' : 'block'} d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                    <path className={isPasswordVisible ? 'hidden' : 'block'} d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                    <path className={isPasswordVisible ? 'hidden' : 'block'} d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                    <line className={isPasswordVisible ? 'hidden' : 'block'} x1="2" x2="22" y1="2" y2="22"></line>
                    <path className={isPasswordVisible ? 'block' : 'hidden'} d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                    <circle className={isPasswordVisible ? 'block' : 'hidden'} cx="12" cy="12" r="3"></circle>
                </svg>
            </button>
            {errorMessage && <p className="text-red-500 text-lg italic">{errorMessage}</p>}
        </div>
    );
};

export default PasswordInput;
