import { useState } from 'react';

const FeedbackModal = ({ isOpen, onClose }) => {
  const [feedback, setFeedback] = useState('');
  const [rating, setRating] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [feedbackStatus, setFeedbackStatus] = useState(null);

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    const feedbackData = {
      feedback,
      rating,
    };

    // try {
    //   const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/feedback/`, {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(feedbackData),
    //   });

    //   if (response.ok) {
    //     setFeedbackStatus('success');
    //     setFeedback('');
    //     setRating(0);
    //   } else {
    //     setFeedbackStatus('error');
    //   }
    // } catch (error) {
    //   console.error('Error submitting feedback:', error);
    //   setFeedbackStatus('error');
    // } finally {
    //   setSubmitting(false);
    // }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-4 sm:p-6 rounded-lg max-w-md mx-auto">
        <h2 className="text-2xl font-bold mb-4 text-center">ArtVista</h2>
        {feedbackStatus === 'success' && (
          <p className="text-green-500 mb-4 text-center">Feedback submitted successfully!</p>
        )}
        {feedbackStatus === 'error' && (
          <p className="text-red-500 mb-4 text-center">Failed to submit feedback. Please try again later.</p>
        )}
        <p className="text-center">Have an idea, suggestion, or general feedback? Share it here!</p>
        <form  action="https://formspree.io/f/xyzgzqnr" method="POST">
          <div className="mb-4">
            <label name='rating' className="block text-sm text-center font-medium text-gray-700">Rating</label>
            <div className="flex justify-center mt-2">
              {[1, 2, 3, 4, 5].map((value) => (
                <span
                  key={value}
                  className={`text-2xl cursor-pointer ${rating >= value ? 'text-yellow-500' : 'text-gray-400'}`}
                  onClick={() => handleRatingChange(value)}
                >
                  {String.fromCharCode(9733)}
                </span>
              ))}
            </div>
          </div>
          <div className="mb-4">
          <label className='block text-center' htmlFor="email">Email</label>
           <input type="email" className="form-control" id="email" name='email' placeholder="Enter your email" required/>
            <label htmlFor="feedback" className="block text-center font-medium text-gray-700">Feedback</label>
            <textarea
              id="feedback"
              name='feedback'
              value={feedback}
              onChange={handleFeedbackChange}
              rows="4"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              placeholder="Enter your feedback here"
              required
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${submitting ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={submitting}
            >
              {submitting ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FeedbackModal;
