import React from 'react';

const Terms = () => {
  return (
    <div className="container3 mx-auto mt-8">
      <h1 className="text-3xl font-bold mb-8 text-center">Terms and Conditions</h1>
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-xl font-semibold mb-4">ArtVista Terms of Use</h2>
        <p>Effective as of 01/01/2024</p>
        <p>Welcome to the ArtVista Terms of Use agreement. For purposes of this agreement, “Site” refers to the Company’s website, which can be accessed at [https://theartvista.com/]. “Service” refers to the Company’s services accessed via the Site, in which users can use our services. The terms “we,” “us,” and “our” refer to the Company. “You” refers to you, as a user of our Site or our Service. The following Terms of Use apply when you view or use the Service via our website [https://theartvista.com/]</p>
        <p>Please review the following terms carefully. By accessing or using the Service, you signify your agreement to these Terms of Use. If you do not agree to be bound by these Terms of Use in their entirety, you may not access or use the Service.</p>
        <h3 className="text-lg font-semibold mb-2">PRIVACY POLICY</h3>
        <p>The Company respects the privacy of its Service users. Please refer to the Company’s Privacy Policy which explains how we collect, use, and disclose information that pertains to your privacy. When you access or use the Service, you signify your agreement to the Privacy Policy as well as these Terms of Use.</p>
        <h3 className="text-lg font-semibold mb-2">ABOUT THE SERVICE</h3>
        <p>The Service allows you to generate content, reporting, enforcement, copyright claims, counterclaims, adhere to community guidelines, adhere to content guidelines.</p>
        <h3 className="text-lg font-semibold mb-2">REGISTRATION; RULES FOR USER CONDUCT AND USE OF THE SERVICE</h3>
        <p>You need to be at least 13 years old to register for and use the Service.</p>
        <p>If you are a user who signs up for the Service, you will create a personalized account that includes a unique username and a password to access the Service and to receive messages from the Company. You agree to notify us immediately of any unauthorized use of your password and/or account. The Company will not be responsible for any liabilities, losses, or damages arising out of the unauthorized use of your member name, password, and/or account.</p>
        <h3 className="text-lg font-semibold mb-2">USER RESTRICTIONS</h3>
        <p>Your permission to use the Site is conditioned upon the following use, posting, and conduct restrictions:</p>
        <ul>
          <li>Access the Service for any reason other than your personal, non-commercial use solely as permitted by the normal functionality of the Service.</li>
          <li>Collect or harvest any personal data of any user of the Site or the Service.</li>
          <li>Use the Site or the Service for the solicitation of business in the course of trade or in connection with a commercial enterprise.</li>
          <li>Distribute any part or parts of the Site or the Service without our explicit written permission (we grant the operators of public search engines permission to use spiders to copy materials from the site for the sole purpose of creating publicly-available searchable indices but retain the right to revoke this permission at any time on a general or specific basis).</li>
          <li>Use the Service for any unlawful purpose or for the promotion of illegal activities.</li>
          <li>Attempt to, or harass, abuse or harm another person or group.</li>
          <li>Use another user’s account without permission.</li>
          <li>Intentionally allow another user to access your account.</li>
          <li>Provide false or inaccurate information when registering an account.</li>
          <li>Interfere or attempt to interfere with the proper functioning of the Service.</li>
          <li>Make any automated use of the Site, the Service or the related systems, or take any action that we deem to impose or to potentially impose an unreasonable or disproportionately large load on our servers or network infrastructure.</li>
          <li>Bypass any robot exclusion headers or other measures we take to restrict access to the Service, or use any software, technology, or device to scrape, spider, or crawl the Service or harvest or manipulate data.</li>
          <li>Circumvent, disable or otherwise interfere with any security-related features of the Service or features that prevent or restrict use or copying of content, or enforce limitations on use of the Service or the content accessible via the Service.</li>
          <li>Publish or link to malicious content of any sort, including that intended to damage or disrupt another user’s browser or computer.</li>
        </ul>
        <h3 className="text-lg font-semibold mb-2">POSTING AND CONDUCT RESTRICTIONS</h3>
        <p>When you create your own personalized account, you may be able to provide [Description of Info That May Be Provided]during the registration process. Users are responsible for managing their accounts and ensuring the accuracy of their information., you may be able to provide (“User Content”) to the Service. You are solely responsible for the User Content that you post, upload, link to, or otherwise make available via the Service.</p>
        <p>You agree that we are only acting as a passive conduit for your online distribution and publication of your User Content. The Company, however, reserves the right to remove any User Content from the Service at its sole discretion.</p>
        <p>We grant you permission to use and access the Service, subject to the following express conditions surrounding User Content. You agree that failure to adhere to any of these conditions constitutes a material breach of these Terms.</p>
        <p>By transmitting and submitting any User Content while using the Service, you agree as follows:</p>
        <ul>
          <li>You are solely responsible for your account and the activity that occurs while signed in to or while using your account;</li>
          <li>You will not post information that is malicious, libelous, false or inaccurate;</li>
          <li>You will not post any information that is abusive, threatening, obscene, defamatory, libelous, or racially, sexually, religiously, or otherwise objectionable and offensive;</li>
          <li>You retain all ownership rights in your User Content but you are required to grant the following rights to the Site and to users of the Service as set forth more fully under the “License Grant” and “Intellectual Property” provisions below: When you upload or post User Content to the Site or the Service, you grant to the Site a worldwide, non-exclusive, royalty-free, transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform that Content in connection with the provision of the Service; and you grant to each user of the Service, a worldwide, non-exclusive, royalty-free license to access your User Content through the Service, and to use, reproduce, distribute, prepare derivative works of, display and perform such Content to the extent permitted by the Service and under these Terms of Use;</li>
          <li>You will not submit content that is copyrighted or subject to third party proprietary rights, including privacy, publicity, trade secret, or others, unless you are the owner of such rights or have the appropriate permission from their rightful owner to specifically submit such content; and</li>
          <li>You hereby agree that we have the right to determine whether your User Content submissions are appropriate and comply with these Terms of Service, remove any and/or all of your submissions, and terminate your account with or without prior notice.</li>
        </ul>
        <p>You understand and agree that any liability, loss, or damage that occurs as a result of the use of any User Content that you make available or access through your use of the Service is solely your responsibility. The Site is not responsible for any public display or misuse of your User Content.</p>
        <p>The Site does not, and cannot, pre-screen or monitor all User Content. However, at our discretion, we, or the technology we employ, may monitor and/or record your interactions with the Service or with other Users.</p>
      </div>
      <div className="mt-8 text-center">
        <a href="/" className="inline-block bg-black hover:bg-gray-800 text-white font-semibold py-2 px-4 rounded">
          Back to Home
        </a>
      </div>
    </div>
  );
}

export default Terms;
