
const NotFoundPage = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <h1 className="text-9xl font-extrabold text-blue-600 mb-4">404</h1>
            <h2 className="text-3xl font-semibold text-gray-800 mb-2">Page Not Found</h2>
            <p className="text-lg text-gray-600 mb-8 text-center px-6">
                Sorry, the page you're looking for doesn't exist. If you think something is broken, report a problem.
            </p>
            <a href="/" className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 transition duration-300">
                Go Home
            </a>
        </div>
    );
};

export default NotFoundPage;
