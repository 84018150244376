
import React from 'react';
import { Link } from 'react-router-dom';

const PasswordResetSuccess = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center max-w-md">
        <h2 className="text-2xl font-semibold mb-4">Password Reset Successful</h2>
        <p className="mb-6">Your password has been reset successfully. You can now log in with your new password.</p>
        <Link to="/login" className="inline-block bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700">
          Go to Login
        </Link>
      </div>
    </div>
  );
};

export default PasswordResetSuccess;
