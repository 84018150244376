import React from 'react';

const About = () => {
  return (
    <section id="about" className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-8">About What We Do</h2>
        <p className="text-xl text-center mb-12">For: Artists, Art Enthusiasts/Collectors, Art Galleries, and Museums.</p>

        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/2 px-4 mb-8 md:mb-0">
            <h3 className="text-2xl font-semibold mb-4">Dissatisfied with:</h3>
            <ul className="list-disc list-inside">
              <li className="mb-2">Physical Exhibitions: Artists may find it challenging to reach a broader audience beyond those who can physically attend the exhibition.</li>
              <li className="mb-2">Art Shows and Fairs: Smaller or emerging artists may find it difficult to secure space or afford participation fees, limiting their exposure.</li>
              <li className="mb-2">Print Media Advertising: Traditional advertising in newspapers, magazines, and art journals lacks interactivity and engagement.</li>
              <li className="mb-2">Gallery Representation: High commissions and exclusivity agreements, not inclusive for all artists.</li>
              <li className="mb-2">Auction Houses: High entry barriers and competitive bidding processes.</li>
              <li className="mb-2">Museum Exhibitions: Limited interactivity and diversity in artists and artworks.</li>
              <li className="mb-2">Artist Residencies: Limited access for artists without certain credentials or connections.</li>
            </ul>
          </div>

          <div className="w-full md:w-1/2 px-4">
            <h3 className="text-2xl font-semibold mb-4">Due to:</h3>
            <ul className="list-disc list-inside mb-8">
              <li className="mb-2">Limited visibility</li>
              <li className="mb-2">Community engagement</li>
              <li className="mb-2">Art resources and information</li>
              <li className="mb-2">Difficulty in reaching the target audience</li>
              <li className="mb-2">Genuine connections</li>
            </ul>

            <h3 className="text-2xl font-semibold mb-4">That provides:</h3>
            <ul className="list-disc list-inside">
              <li className="mb-2">Visibility and Exposure</li>
              <li className="mb-2">Community Building</li>
              <li className="mb-2">Monetization Opportunities</li>
              <li className="mb-2">Artistic Development</li>
              <li className="mb-2">Curation and Discovery</li>
              <li className="mb-2">Industry Insights</li>
              <li className="mb-2">Support for Art Entities</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
