import { useState } from 'react';
import axios from 'axios';

const ForgotPassword = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post('https://artvista-zozv.onrender.com/auth/users/reset_password/', { email });
      if (response.status === 204) {
        setMessage('Password reset link sent. Please check your email. Check spam if you didn\'t get the mail. You have to be registered to get this email.');
      } else {
        setMessage('An error occurred. Please try again later.');
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setMessage('CORS error: Make sure your backend server allows requests from your frontend.');
      } else {
        setMessage('An error occurred. Please try again later.');
      }
    }

    setIsLoading(false);
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-90">
      <div className="bg-gray-900 p-8 rounded-lg shadow-lg max-w-lg w-full relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 bg-gray-700 text-white rounded-full w-6 h-6 flex items-center justify-center text-xs hover:bg-gray-600"
        >
          &times;
        </button>
        <h2 className="text-3xl font-bold text-white mb-6">Forgot Password</h2>
        <p className="text-gray-400 mb-6">Enter your email address to reset your password.</p>
        {message && (
          <p className={`mb-6 ${message.includes('sent') ? 'text-green-500' : 'text-red-500'}`}>
            {message}
          </p>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="relative">
            <input
              type="email"
              id="email"
              placeholder="Email address"
              className="w-full px-4 py-3 border border-gray-700 bg-gray-800 text-white rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={isLoading}
            />
          </div>
          <button
            type="submit"
            className={`w-full py-3 font-bold rounded-lg text-white ${
              isLoading ? 'bg-gray-600' : 'bg-blue-600 hover:bg-blue-500'
            }`}
            disabled={isLoading}
          >
            {isLoading ? 'Submitting...' : 'Send Reset Link'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
