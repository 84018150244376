import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import PasswordInput from './PasswordInput';

const JoinForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    email: '',
    username: '',
    password: '',
    re_password: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [errors, setErrors] = useState([]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let newErrors = [];

    if (!formData.email) {
      newErrors.push({ field: 'email', message: 'Email is required.' });
    }
    if (!formData.username) {
      newErrors.push({ field: 'username', message: 'Username is required.' });
    }
    if (!formData.password) {
      newErrors.push({ field: 'password', message: 'Password is required.' });
    }
    if (!formData.re_password) {
      newErrors.push({ field: 're_password', message: 'Confirm Password is required.' });
    }
    if (formData.password && formData.re_password && formData.password !== formData.re_password) {
      newErrors.push({ field: 're_password', message: 'Passwords do not match.' });
    }

    if (newErrors.length > 0) {
      setErrors(newErrors);
      setIsLoading(false);
    } else {
      try {
        await axios.post(`https://artvista-zozv.onrender.com/api/auth/users/`, formData);
        setIsLoading(false);
        onClose();
        navigate(`/activate`);
      } catch (error) {
        const errorMessages = error.response.data || { "unknown_error": "An error occurred. Ensure the details are correct and try again" };
        const formattedErrors = Object.keys(errorMessages).reduce((acc, key) => {
          errorMessages[key].forEach((message) => {
            acc.push({ field: key, message });
          });
          return acc;
        }, []);
        setErrors(formattedErrors);
        setIsLoading(false);
      }
    }
  };

  const getErrorMessage = (field) => {
    const error = errors.find(error => error.field === field);
    return error ? error.message : '';
  };

  return (
    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black p-6 sm:p-8 rounded-lg z-20 w-11/12 sm:max-w-md">
      <h2 className="text-2xl text-white-900 font-bold mb-4">Join The ArtVista Community</h2>
      {getErrorMessage('unknown_error') && <p className='text-center text-red-700'>{getErrorMessage('unknown_error')}</p>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="email" className="block text-gray-700 font-bold mb-1">Email</label>
          <input
            type="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            disabled={isLoading}
            className={`shadow appearance-none border ${getErrorMessage('email') ? 'border-red-500' : 'border-gray-300'} rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
          />
          {getErrorMessage('email') && <p className="text-red-500 text-xs italic">{getErrorMessage('email')}</p>}
        </div>
        <div>
          <label htmlFor="username" className="block text-gray-700 font-bold mb-1">Username</label>
          <input
            type="text"
            id="username"
            value={formData.username}
            onChange={handleChange}
            disabled={isLoading}
            className={`shadow appearance-none border ${getErrorMessage('username') ? 'border-red-500' : 'border-gray-300'} rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
          />
          {getErrorMessage('username') && <p className="text-red-500 text-xs italic">{getErrorMessage('username')}</p>}
        </div>
        <div>
          <label htmlFor="password" className="block text-gray-700 font-bold mb-1">Password</label>
          <PasswordInput
            id="password"
            value={formData.password}
            onChange={handleChange}
            disabled={isLoading}
            placeholder="Enter password"
            errorMessage={getErrorMessage('password')}
          />
        </div>
        <div>
          <label htmlFor="re_password" className="block text-gray-700 font-bold mb-1">Confirm Password</label>
          <PasswordInput
            id="re_password"
            value={formData.re_password}
            onChange={handleChange}
            disabled={isLoading}
            placeholder="Confirm password"
            errorMessage={getErrorMessage('re_password')}
          />
        </div>
        <div className="flex justify-end space-x-2">
          <button
            type="button"
            className="bg-gray-400 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={onClose}
            disabled={isLoading}
          >
            Close
          </button>
          <button
            type="submit"
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            disabled={isLoading}
          >
            {isLoading ? 'Joining...' : 'Join'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default JoinForm;
