import React from 'react';
import co4 from '../images/co4.jpg';
import co5 from '../images/co5.jpg';
import co7 from '../images/co7.jpg';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles

function ReadCorner() {
  return (
    <div className="read-corner bg-white-200 py-2 px-0 sm:px-8 lg:px-16">
      <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-between">
        <div className="text-section lg:w-1/3 lg:mr-8">
          <h3 className="subtitle lg:text-left text-2xl font-bold mb-4">Read Corner</h3>
          <h2 className="title lg:text-left text-3xl font-bold mb-4">Latest</h2>
          <ul className="categories lg:text-left mb-4">
            <li>Contemporary Art</li>
            <li>Modern Art</li>
            <li>Classic Art</li>
          </ul>
          <a href="/explore" className="explore-link text-center lg:text-left text-blue-600 hover:text-blue-800 font-bold">Explore Art &gt;</a>
        </div>
        <div className="image-section mt-8 lg:mt-0 lg:w-2/3">
          <Carousel
            autoPlay
            infiniteLoop
            showThumbs={false}
            showStatus={false}
            interval={7000}
            className="rounded-xl"
          >
            <div>
              <img src={co4} alt="Latest Art 1" className="carousel-image object-cover w-full h-96 lg:h-auto" />
            </div>
            <div>
              <img src={co5} alt="Latest Art 2" className="carousel-image object-cover w-full h-96 lg:h-auto" />
            </div>
            <div>
              <img src={co7} alt="Latest Art 3" className="carousel-image object-cover w-full h-96 lg:h-auto" />
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default ReadCorner;
