import React, { useState } from 'react';
import logo from '../images/ArtVista.jpg';
import { FaEnvelope, FaWhatsapp, FaInstagram, FaTwitter } from 'react-icons/fa';
import ErrorModal from './ErrorModal';
import FeedbackModal from './FeedbackModal';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubscribeClick = async (e) => {
    e.preventDefault();

    if (!email) {
      setAlertMessage('Please enter your email address.');
      setIsError(true);
      setIsModalOpen(true);
      return;
    }

    try {
      const response = await fetch(`https://artvista-zozv.onrender.com/api/subscribe/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setAlertMessage('Subscription successful!');
        setIsError(false);
        setIsModalOpen(true);
      } else {
        setAlertMessage('Subscription failed. Please try again later.');
        setIsError(true);
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error('Error subscribing:', error);
      setAlertMessage('An error occurred while subscribing. Please try again later.');
      setIsError(true);
      setIsModalOpen(true);
    }

    setEmail('');
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFeedbackClick = () => {
    setIsFeedbackModalOpen(true);
  };

  const handleFeedbackClose = () => {
    setIsFeedbackModalOpen(false);
  };

  return (
    <div className='p-10'>
      <hr className="mt-1 mb-1 border-gray-300" />
      <footer className="bg-white-200 text-dark p-1">
        <ErrorModal isOpen={isModalOpen} onClose={handleCloseModal} message={alertMessage} isError={isError} />
        <FeedbackModal isOpen={isFeedbackModalOpen} onClose={handleFeedbackClose} />
        <a href='/' className='mr-10'><img src={logo} alt='logo' className='logo' style={{ width: '100px', height: '50px' }} /></a>

        <div className="mt-7 grid grid-cols-1 md:grid-cols-3 gap-4 grid-container">
          <div>
            <h3 className="text-lg font-semibold mb-2">Join our Waiting List</h3>
            <p className="text-gray-600">Be the first to know about our latest updates and offers.</p>
            <form className="mt-4" onSubmit={handleSubscribeClick}>
              <div className="flex flex-col md:flex-row">
                <input
                  type="email"
                  placeholder="Your Email"
                  required
                  className="border border-gray-300 px-1 py-1 rounded-md mb-2 md:mr-2 md:mb-0"
                  value={email}
                  onChange={handleInputChange}
                />
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-green-400 text-white font-bold py-1 px-1 rounded text-sm"
                >
                  Subscribe
                </button>
              </div>
            </form>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-2">Contact Info</h3>
            <div className="flex md:flex-row  mb-2">
              <FaEnvelope aria-label="Email" className="text-gray-600 mr-2" />
              <p className="text-gray-600">info@artvista.com</p>
            </div>
            <div className="flex md:flex-row  mb-2">
              <FaWhatsapp aria-label="WhatsApp" className="text-gray-600 mr-2" />
              <a href="https://chat.whatsapp.com/KyPRBAYlvSM8Lo6nwxRP69" className="text-gray-600 hover:text-blue-500 transition duration-300" target="_blank" rel="noopener noreferrer">
                WhatsApp
              </a>
            </div>
            <div className="flex md:flex-row mb-2">
              <FaInstagram aria-label="Instagram" className="text-gray-600 mr-2" />
              <a href="https://www.instagram.com/artvista_1/" className="text-gray-600 hover:text-blue-500 transition duration-300">
                Instagram
              </a>
            </div>
            <div className="flex md:flex-row  mb-2">
              <FaTwitter aria-label="Twitter" className="text-gray-600 mr-2" />
              <a href="https://twitter.com/Artvista82901" className="text-gray-600 hover:text-blue-500 transition duration-300" target="_blank" rel="noopener noreferrer">
                Twitter
              </a>
            </div>
            <button
              type="button"
              className="text-blue-500 hover:underline focus:outline-none"
              onClick={handleFeedbackClick}
            >
              Feedback
            </button>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-2">Terms & Conditions</h3>
            <p>
              <a href="/terms" className="text-gray-600">Terms of Use</a>
            </p>
          </div>
        </div>

        <div className="mt-8 text-sm text-gray-600">
          &copy; {new Date().getFullYear()} Artvista LTD. All rights reserved.
        </div>
      </footer>
    </div>
  );
}

export default Footer;
