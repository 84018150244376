import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import logo from '../images/ArtVista.jpg';
import AuthContext from './AuthContext';

const Navbar = ({ onLoginClick, onJoinClick }) => {
  const { isAuthenticated, logout } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const navLinks = [
    { path: '/', label: 'Home' },
    { path: '/gallery', label: 'Gallery' },
    { path: '/events', label: 'Events' },
    { path: '/blog', label: 'Blog' },
    { path: '/contacts', label: 'Contacts' }
  ];

  return (
    <nav className="bg-gradient-to-r from-white-800 to-gray-900 shadow-lg">
      <div className="max-w-screen-xl mx-auto px-4 py-3 flex justify-between items-center">
        <a href="/" className="flex items-center space-x-3">
          <img src={logo} alt="logo" className="h-14" />
        </a>
        <div className="md:hidden">
          <button
            onClick={toggleMenu}
            className="text-black focus:outline-none focus:bg-black-700 rounded-lg p-2"
            aria-label="Toggle menu"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              )}
            </svg>
          </button>
        </div>
        <div className={`md:flex md:items-center ${isOpen ? 'block' : 'hidden'} md:block`}>
          <ul className="md:flex md:space-x-4 space-y-4 md:space-y-0 md:ml-auto">
            {navLinks.map(
              (link) =>
                location.pathname !== link.path && (
                  <li key={link.path}>
                    <a href={link.path} className={`text-black px-3 py-1 ${location.pathname === link.path ? 'bg-gray-800' : ''}`}>
                      {link.label}
                    </a>
                  </li>
                )
            )}
          </ul>
          <div className="hidden md:flex items-center space-x-4">
            {!isAuthenticated && (
              <>
                <button onClick={onLoginClick} className="bg-black hover:bg-gray-800 text-white px-4 py-2 rounded-md shadow-lg transition-transform transform hover:scale-105">
                  Login
                </button>
                <button onClick={onJoinClick} className="bg-black hover:bg-gray-800 text-white px-4 py-2 rounded-md shadow-lg transition-transform transform hover:scale-105">
                  Sign up
                </button>
              </>
            )}
            {isAuthenticated && (
              <>
                {/* <button className="bg-black hover:bg-gray-800 text-white focus:ring-4 focus:ring-gray-700 px-4 py-2 rounded-md">
                  <FaBell className="w-6 h-6" />
                </button> */}
                <button onClick={logout} className="bg-black hover:bg-gray-800 text-white px-4 py-2 rounded-md shadow-lg transition-transform transform hover:scale-105">
                  Sign out
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
