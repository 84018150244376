import { useState } from 'react';
import { useParams , useNavigate } from 'react-router-dom';
import axios from 'axios';
import PasswordInput from './PasswordInput';

const PasswordResetConfirm = () => {
  const { uid, token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({}); // Errors are stored as an object with field names as keys
  const [isLoading, setIsLoading] = useState(false);
  const navigate  =useNavigate()

  const handlePasswordReset = async () => {
    setIsLoading(true);
    try {
      await axios.post(`https://artvista-zozv.onrender.com/api/auth/users/reset_password_confirm/`, {
        uid,
        token,
        new_password: newPassword,
        re_new_password: confirmPassword,
      });
      setMessage('Password reset successfully. You can now log in with your new password.');
      setErrors({});
      navigate('/password/reset/success/')
    } catch (err) {
      if (err.response?.data) {
        const errorData = err.response.data;
        const newErrors = {};

        // Assign errors to respective fields
        if (errorData.new_password) {
          newErrors.new_password = errorData.new_password;
        }
        if (errorData.re_new_password) {
          newErrors.re_new_password = errorData.re_new_password;
        }
        if (errorData.token) {
          newErrors.token = errorData.token;
        }
        if (errorData.uid) {
          newErrors.uid = errorData.uid;
        }
        if (errorData.non_field_errors) {
          newErrors.non_field_errors = errorData.non_field_errors;
        }

        setErrors(newErrors);
      } else {
        setErrors({ non_field_errors: ['Failed to reset password. Please try again.'] });
      }
      setMessage('');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-4xl font-bold text-gray-800 mb-4 text-center">Reset Your Password</h1>
      <p className="text-gray-600 mb-6 text-center max-w-lg">
        Please enter your new password below.
      </p>
      {errors.non_field_errors && (
        <div className="text-red-600 mb-4">
          {errors.non_field_errors.map((error, index) => (
            <p key={index}>{error}</p>
          ))}
        </div>
      )}
      {errors.token && (
        <div className="text-red-600 mb-4">{errors.token}</div>
      )}
      {message && (
        <p className="w-full max-w-lg p-4 mb-4 text-blue-900 bg-blue-100 border border-blue-400 rounded">
          {message}
        </p>
      )}
      <div className="flex flex-col w-[500px] gap-4">
        <PasswordInput
          id="new-password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="Enter new password"
          errorMessage={
            errors.new_password && (
                <div className="text-red-600 mt-1">
                  {errors.new_password.map((error, index) => (
                    <span key={index}>{error}</span>
                  ))}
                </div>
              )
          }
        />
        <PasswordInput
          id="confirm-password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm new password"
          errorMessage={
            errors.re_new_password && (
                <div className="text-red-600 mt-1">
                  {errors.re_new_password.map((error, index) => (
                    <span key={index}>{error}</span>
                  ))}
                </div>
              )
          }
        />
      </div>

      <button
        onClick={handlePasswordReset}
        className={`px-6 py-2 mt-4 ${isLoading ? 'bg-gray-400' : 'bg-blue-600'} text-white rounded hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
        disabled={isLoading}
      >
        {isLoading ? 'Resetting...' : 'Reset Password'}
      </button>
    </div>
  );

};

export default PasswordResetConfirm;
