import React from 'react';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';

const TestimonyCard = ({ testimony }) => {
  return (
    <div className="bg-blue-400 rounded-lg shadow-lg p-6">
      <div className="text-black-900 mb-4">
        <FaQuoteLeft className="inline text-2xl text-black-500 mr-2" />
        {testimony.quote}
        <FaQuoteRight className="inline text-2xl text-black-500 ml-2" />
      </div>
      <div className="text-lg font-semibold mb-2">{testimony.author}</div>
      <div className="text-sm text-black-500">{testimony.role}</div>
    </div>
  );
};

export default TestimonyCard;
